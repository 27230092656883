import "github-markdown-css/github-markdown.css";
import "prismjs/themes/prism-tomorrow.css";

import ReactDOM from "react-dom/client";

export const replaceHydrateFunction = () => {
  return (element: React.ReactNode, container: Element | DocumentFragment) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};
